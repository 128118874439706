"use client";

import { useEffect } from "react";
import ReactGA from "react-ga4";
import OldReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { usePathname, useSearchParams } from "next/navigation";
import { useAppSelector } from "@/lib/redux/hook";

const useAnalyticsTracker = () => {
  const user = useAppSelector((state) => state.user);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const facebookId = user?.userAnalytics?.facebookPixelId;
  const googleId = user?.userAnalytics?.googleAnalyticsId;
  console.log({user, googleId})
  useEffect(() => {
    const gaVersion = googleId ? googleId.slice(0, 2) : "";
    const pagePath =
      pathname + (searchParams ? "?" + searchParams.toString() : "");

    if (googleId) {
      if (gaVersion === "G-") {
        ReactGA.initialize(googleId);
        ReactGA.send({ hitType: "pageview", page: pagePath });
      } else {
        OldReactGA.initialize(googleId);
        OldReactGA.pageview(pagePath);
      }
    }
  }, [pathname, searchParams, googleId]);

  useEffect(() => {
    if (facebookId) {
      ReactPixel.init(facebookId);
      ReactPixel.pageView();
    }
  }, [pathname, searchParams, facebookId]);
};

export default useAnalyticsTracker;
