import { configureStore } from "@reduxjs/toolkit";
import user from "./features/user";
import totalCrossSell from "./features/totalCrossSell";
import crossSellPaymentLinks from "./features/crossSellPaymentLinks";

export const store = configureStore({
  reducer: {
    user,
    totalCrossSell,
    crossSellPaymentLinks,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
