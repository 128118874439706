import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"display\":\"swap\",\"fallback\":[\"system-ui\",\"arial\"],\"variable\":\"--font-nunito-sans\"}],\"variableName\":\"nunitoSans\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"variable\":\"--font-open-sans\",\"display\":\"swap\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/analytics-tracker-global/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/components/embed-manipulator/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloProvider"] */ "/usr/src/app/src/lib/apollo/provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReduxProvider"] */ "/usr/src/app/src/lib/redux/provider.tsx");
