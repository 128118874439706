import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = 0;

export const totalCrossSell = createSlice({
  name: "totalCrossSell",
  initialState,
  reducers: {
    updateTotalCrossSell: (state, action: PayloadAction<number>) => {
      return action.payload;
    },
    resetTotalCrossSell: (state, action: PayloadAction<number>) => {
      return initialState;
    },
  },
});

export const { updateTotalCrossSell, resetTotalCrossSell } =
  totalCrossSell.actions;

export default totalCrossSell.reducer;
