"use client";
import { useEffect } from "react";
import * as ackeeTracker from "ackee-tracker";
import { getUrlParamsAsArray } from "@/utils";
import Cookies from "js-cookie";
import {
  AckeeAnalyticEvent,
  EAckeeAnalyticEvent,
  IAckeeAnalytic,
} from "@/composables/ackee.type";

const collectLocationData = async (
  ackeeInstance: any,
  ackeeAnalytic: IAckeeAnalytic
) => {
  if (!ackeeAnalytic) return;

  let ipAdress = Cookies.get("ipPublicAnalytic");

  if (!ipAdress) {
    const response = await window.fetch("https://api.ipify.org?format=json");

    const dataIp = await response.json();

    ipAdress = dataIp?.ip;

    if (ipAdress) {
      Cookies.set("ipPublicAnalytic", ipAdress, { expires: 7 });
    }
  }

  ackeeInstance.action(ackeeAnalytic.analyticEventId, {
    key: ipAdress,
    value: 1,
  });
};

const useAckeeTracker = (
  ackeeAnalytics: IAckeeAnalytic[],
  eventType: AckeeAnalyticEvent
) => {
  useEffect(() => {
    if (ackeeAnalytics?.length) {
      const analyticDomain = ackeeAnalytics.find(
        (item) => item.analyticDomainId
      );
      const analyticEventUrlParam = ackeeAnalytics.find(
        (item) => item.analyticEventId && item.eventType === eventType
      );
      const analyticEventLocation = ackeeAnalytics.find(
        (item) =>
          item.analyticEventId &&
          item.eventType === EAckeeAnalyticEvent.IP_ADDRESS
      ) as IAckeeAnalytic;

      const ackeeInstance = ackeeTracker.create(
        process.env.NEXT_PUBLIC_ANALYTIC_ACKEE!,
        {
          detailed: true,
          ignoreOwnVisits: false,
          ignoreLocalhost: false,
        }
      );

      if (analyticDomain) {
        ackeeInstance.record(analyticDomain.analyticDomainId);
      }

      // Collecting URL params with ackee custom event
      if (analyticEventUrlParam) {
        const urlParams = getUrlParamsAsArray(window.location.href);

        if (urlParams?.length) {
          for (const key of urlParams ?? []) {
            ackeeInstance.action(analyticEventUrlParam.analyticEventId, {
              key, // Event key (for tracking URL presence)
              value: 1, // 1 meaning the value exists
            });
          }
        }
      }

      collectLocationData(ackeeInstance, analyticEventLocation);
    }
  }, [ackeeAnalytics, eventType]);
};

export const useAckeeGlobalTracker = (pathname: string, domainId: string) => {
  useEffect(() => {
    if (!domainId || !pathname) return;

    const ackeeInstance = ackeeTracker.create(
      process.env.NEXT_PUBLIC_ANALYTIC_ACKEE!,
      {
        detailed: true,
        ignoreOwnVisits: false,
        ignoreLocalhost: false,
      }
    );

    const attributes = ackeeTracker.attributes(true);
    const url = new URL(pathname, window.location.href);

    return ackeeInstance.record(domainId, {
      ...attributes,
      siteLocation: url.href,
    }).stop;
  }, [pathname, domainId]);
};

export default useAckeeTracker;
