import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CrossSellPaymentLink = {
  crossSellPaymentLink: {
    amount: number | null;
    baseAmount: number | null;
    coverImage: {
      url: string;
    };
    id: string;
    link: string;
    multipleImage: any;
    name: string;
    type: string;
    variant: any;
  };
  id: string;
  discountType: any;
  discountValue: any;
  variantId: string;
};

const initialState = [] as CrossSellPaymentLink[];

export const crossSellPaymentLinks = createSlice({
  name: "crossSellPaymentLinks",
  initialState,
  reducers: {
    addCrossSellPaymentLinks: (
      state,
      action: PayloadAction<CrossSellPaymentLink>
    ) => {
      return [...state, action.payload];
    },
    subtractCrossSellPaymentLinks: (
      state,
      action: PayloadAction<CrossSellPaymentLink>
    ) => {
      return state.filter(({ id }) => id !== action.payload.id);
    },
    resetCrossSellPaymentLinks: () => {
      return initialState;
    },
  },
});

export const {
  addCrossSellPaymentLinks,
  subtractCrossSellPaymentLinks,
  resetCrossSellPaymentLinks,
} = crossSellPaymentLinks.actions;

export default crossSellPaymentLinks.reducer;
