import { IUser } from "@/composables/user.type";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const user = createSlice({
  name: "user",
  initialState: {} as IUser,
  reducers: {
    updateCurrentUser: (state: IUser, action: PayloadAction<IUser>) => {
      return (state = action.payload);
    },
  },
});

export const { updateCurrentUser } = user.actions;

export default user.reducer;
