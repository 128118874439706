"use strict";
"use client";

import React, { useEffect } from "react";

type MayarMessage = {
  target: string;
  data: {
    layoutMode: string;
    hideMerchantLogo: boolean;
    hideProductImages: boolean;
    hideHeader: boolean;
    hideLanguage: boolean;
    hideDescProduct: boolean;
    hideBadgeSecure: boolean;
  };
};

type KeyData = keyof MayarMessage["data"];

const EmbedManipulator = () => {
  useEffect(() => {
    let mayarMessage = {} as MayarMessage;

    window.addEventListener(
      "message",
      (e) => {
        if (e.data.target === "mayar-checkout-embed") {
          mayarMessage = e.data;
        }

        if (mayarMessage.target === "mayar-checkout-embed") {
          if (mayarMessage.data.layoutMode === "SIMPLE") {
            mayarMessage.data.hideMerchantLogo = true;
            mayarMessage.data.hideProductImages = true;
            mayarMessage.data.hideHeader = true;
            mayarMessage.data.hideLanguage = true;
            mayarMessage.data.hideDescProduct = true;
            mayarMessage.data.hideBadgeSecure = true;
          }

          const brokenLink = document.getElementById("broken-link");
          if (brokenLink) {
            brokenLink.style.minHeight = "fit-content";
          }

          (function setCustomEmbedPage() {
            for (const key in mayarMessage.data) {
              const elementId = `${key
                .replace(/[A-Z]/g, (m) => "-" + m.toLowerCase())
                .replace("hide-", "")}-mayar`;
              const element = document.getElementById(elementId);

              if (mayarMessage.data[key as KeyData] && element) {
                const productPrice = document.getElementById(
                  "product-price-mayar"
                );
                const merchantName = document.getElementById(
                  "merchant-name-mayar"
                );

                if (elementId === "header-mayar" && productPrice) {
                  productPrice.classList.remove("flex");
                  productPrice.classList.add("!hidden");
                } else if (
                  elementId === "merchant-logo-mayar" &&
                  merchantName
                ) {
                  merchantName.classList.remove("flex");
                  merchantName.classList.add("!hidden");
                }

                element.classList.remove("flex");
                element.classList.add("!hidden");
              }
            }
          })();

          const parentMinScreen =
            document.getElementsByClassName("min-h-screen")[0];
          if (parentMinScreen) {
            parentMinScreen.classList.remove("min-h-screen");
          }

          if (document.getElementsByClassName("rui-sign")[0]) {
            document
              .getElementsByClassName("rui-sign")[0]
              .classList.add("!min-h-0", "py-4");

            const backgroundElement =
              document.getElementsByClassName("bg-[#f8f9fa]")[0];
            if (backgroundElement)
              backgroundElement.classList.remove("bg-[#f8f9fa]");

            if (mayarMessage.data.layoutMode === "SIMPLE") {
              document.body.style.background = "none";
              if (document.getElementsByClassName("rui-sign")[0]) {
                document
                  .getElementsByClassName("rui-sign")[0]
                  .classList.remove("py-4");
              }
              if (document.querySelector("form.rui-sign-form")) {
                document
                  ?.querySelector("form.rui-sign-form")
                  ?.classList.remove("rui-sign-form", "rui-sign-form-cloud");
              }
              if (document.getElementById("powered-by-mayar")) {
                document
                  ?.getElementById("powered-by-mayar")
                  ?.classList.add("hidden");
              }
            }
          }
        }
      },
      false
    );

    return () => {
      window.removeEventListener("message", () => {});
    };
  }, []);

  return <></>;
};

export default EmbedManipulator;
